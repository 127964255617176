/*
 * @FilePath: \lxdigits-h5\src\api\request.js
 * @Description: axios封装
 * @Author: 'ltt'
 * @Date: 2024-02-02 14:01:35
 * @LastEditors: 'ltt'
 * @LastEditTime: 2024-02-06 11:06:45
 */
import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
import router from '@/router'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 基础地址
  timeout: 10000
})
service.interceptors.request.use(
  (config) => {
    // 将token添加到请求头中
    if (store?.state?.user?.token || localStorage.getItem('token')) {
      config.headers.Authorization = store?.state?.user?.token || localStorage.getItem('token')
    }
    return config
  },
  (error) => {
    // 失败执行promise
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  async (response) => {
    const { data, code, msg } = response.data // 默认json格式
    //这里要对短信登录  发送验证码 进行单独的处理 
    //不然 验证码没发出去  还进行了读秒  
    if (response.config.url === '/sso/login/getShortMsg') {
      return response.data;
    }
    //直接返回  进行处理
    if (code == 10000) {
      return response.data
    } else if (code == 10003) {
      Toast({
        message: msg,
        position: 'bottom'
      })
      localStorage.clear()
      router.push('/login')
    } else {
      return Toast({
        message: msg,
        position: 'bottom'
      })
    }
  },
  (error) => {
    // return Toast({
    //   message: error.message,
    //   position: 'bottom'
    // })
  }
)
export default service
