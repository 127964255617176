// reqPassLogin: 密码登录 | reqShortMsgLogin: 验证码登录
import { reqPassLogin, reqShortMsgLogin } from '@/api'
const state = {
  token: localStorage.getItem('token')
}
const mutations = {
  // 保存 token
  SETTOKEN(state, token) {
    state.token = token
    localStorage.setItem('token', token)
  }
}
const actions = {
  // 密码登录
  async passLogin(context, value) {
    let res = await reqPassLogin(value)
    if (res.data) {
      context.commit('SETTOKEN', res.data.access_token)
    }
    return res;
  },
  // 验证码登录
  async shortMsgLogin(context, value) {
    let res = await reqShortMsgLogin(value)
    if (res.data) {
      context.commit('SETTOKEN', res.data.access_token)
    }
    return res;
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}

