// 当前这个模块：API进行统一管理
import request from './request'

// 注册获取短信验证码： /sso/register/getShortMsg -- POST -- 带参数:userName
export const reqGetRegisterCode = (data) => request({ url: `/sso/register/getShortMsg`, method: 'POST', data })

// 用户注册： /sso/register/submit -- POST -- 带参数：userName、shortMsg、userPass、confirmPass、userPlatform
export const reqUserRegister = (data) => request({ url: `/sso/register/submit`, method: 'POST', data })

// 登录获取图片验证码：/sso/login/getPassImg -- POST --无参数
export const reqPassImg = () => request({ url: `/sso/login/getPassImg`, method: 'POST' })

// 密码登录：/sso/login/passLogin -- POST -- 带参数：userName、userPass、verifyCode、userPlatform、imgToken
export const reqPassLogin = (data) => request({ url: `/sso/login/passLogin`, method: 'POST', data })

// 免密登录获取短信验证码：/sso/login/getShortMsg -- POST --  带参数：userName
export const reqGetShortMsg = (data) => request({ url: `/sso/login/getShortMsg`, method: 'POST', data })

// 短信验证码登录： /sso/login/shortMsgLogin -- POST -- 带参数：userName、shortMsg、userPlatform
export const reqShortMsgLogin = (data) => request({ url: `/sso/login/shortMsgLogin`, method: 'POST', data })

// 忘记密码获取短信验证码  /sso/login/forgetPassword/getShortMsg  --  POST  --  带参
export const reqShortMsgForget = (data) => request({ url: `/sso/login/forgetPassword/getShortMsg`, method: 'POST', data })

// 忘记密码重置密码  /sso/login/forgetPassword/update  --  POST  --  带参
export const reqForgetSubmit = (data) => request({ url: `/sso/login/forgetPassword/update`, method: 'POST', data })
