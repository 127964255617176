/*
 * @FilePath: \lxdigits-h5\src\router\index.js
 * @Description:路由
 * @Author: 'ltt'
 * @Date: 2024-02-02 14:01:35
 * @LastEditors: 'ltt'
 * @LastEditTime: 2024-02-06 11:05:21
 */
import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: { title: '登录' }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: { title: '注册', keepAlive: true } //网上找的 用于缓存页面
    },
    {
      path: '/service',
      name: 'service',
      component: () => import('@/views/ServiceFile.vue'),
      meta: { title: '服务协议' }
    },
    {
      path: '/enterprisecert',
      name: 'enterprisecert',
      component: () => import('@/views/EnterpriseCert.vue'),
      meta: {
        title: '企业认证'
        // keepAlive: true // 需要缓存
      }
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('@/views/EnterpriseCertSucess.vue'),
      meta: { title: '认证成功' }
    },
    {
      path: '/pdf',
      name: 'pdf',
      component: () => import('@/views/Pdf.vue'),
      meta: { title: '电子签名认证证书授权委托书' }
    }
  ]
})

const whiteList = ['/login', '/register', '/service']
router.beforeEach((to, from, next) => {
  if (store?.state?.user?.token) {
    next()
  } else {
    // 没有token
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login') // 中转到登录页
    }
  }
  next()
})
router.afterEach((to, from) => {
  document.title = to.meta.title
})
export default router
